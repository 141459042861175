/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-25 16:52:30
 * @Module: 聊天主要内容
 */
 <template>
  <div class="chatContent"
       v-loading="loading">
    <div class="chatContent-t">
      {{friendInfo?friendInfo.nickname:''}}
      <div class="chatContent-t-btns">
        <el-dropdown trigger="click"
                     @command="setting">
          <span class="iconfont icongengduo hover"></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="jinyan">开启群成员禁言</el-dropdown-item>
            <el-dropdown-item command="ruqun">关闭入群审批</el-dropdown-item>
            <el-dropdown-item command="guanliyuan">设置管理员</el-dropdown-item>
            <el-dropdown-item command="zhuanrang">转让群主</el-dropdown-item>
            <el-dropdown-item command="jiesan">退出并解散群聊</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span class="iconfont iconziliao hover"
              @click="groupInfoClick"></span>
        <span class="iconfont iconfenxiang1 hover"></span>
      </div>
    </div>
    <div style="position:relative">
      <!-- 群组信息 -->
      <groupInfo ref="groupInfo" />
      <div class="chatContent-c"
           id="chatContent-box"
           v-if="!loading"
           @scroll="paperScroll">
        <div v-for="(item,index) in messageList"
             :key="item.id">
          <!-- 对方消息 -->
          <div class="chatContent-time">{{index|time(that)}}</div>
          <div class="chatContent-c-friend"
               v-if="friend_id==item.user_id">
            <div class="chatContent-c-friend-avatar">
              <el-avatar :size="42"
                         :src="friendInfo?friendInfo.avatar:''"></el-avatar>
            </div>
            <div class="chatContent-c-friend-message">
              <div class="chatContent-c-friend-triangle"></div>
              <el-image :src="item.content"
                        style="border-radius: 6px;"
                        :style="imageSize(item.content)"
                        v-if="item.message_type=='image'"></el-image>
              <span v-else>{{item.content}}</span>
            </div>
          </div>
          <!-- 自己消息 -->
          <div class="chatContent-c-mine"
               v-if="friend_id!==item.user_id">
            <div class="chatContent-c-mine-message">
              <div class="chatContent-c-mine-triangle"></div>
              <el-image :src="item.content"
                        :style="imageSize(item.content)"
                        style="border-radius: 6px;"
                        v-if="item.message_type=='image'"></el-image>
              <span v-else>{{item.content}}</span>
            </div>
            <div class="chatContent-c-mine-avatar">
              <el-avatar :size="42"
                         :src="userInfo.avatar"></el-avatar>
            </div>
          </div>
        </div>
        <!-- 发送中的消息 -->
        <div class="chatContent-c-mine"
             v-for="item in sendingList"
             :key="item.id">
          <div class="sending">
            <i class="el-icon-loading"></i>
          </div>
          <div class="chatContent-c-mine-message">
            <div class="chatContent-c-mine-triangle"></div>
            <el-image :src="item.content"
                      :style="imageSize(item.content)"
                      style="border-radius: 6px;"
                      v-if="item.message_type=='image'"></el-image>
            <span v-else>{{item.content}}</span>
          </div>
          <div class="chatContent-c-mine-avatar">
            <el-avatar :size="42"
                       :src="userInfo.avatar"></el-avatar>
          </div>
        </div>
        <!-- 发送中的消息-end -->
      </div>
    </div>
    <div class="chatContent-b"
         v-if="!loading">
      <div class="chatContent-b-t">
        <add-image @on-success="addImageSuccess" />
      </div>
      <div class="chatContent-b-c">
        <el-input type="textarea"
                  :rows="7"
                  resize="none"
                  placeholder="请输入内容"
                  @keydown.enter.native="keyDown"
                  v-model="textarea" />
      </div>
      <div class="chatContent-b-b">
        <div class="chatContent-b-b-help">按Enter键发送</div>
        <div class="chatContent-b-b-btn hover"
             @click="sendFn">发送</div>
      </div>
    </div>
    <userRadiobox ref="userRadiobox"
                  title="选择新群主" />
  </div>
</template>
 <script>
import { mapState } from "vuex";
import userInfo from "@/mixins/userinfo"
import { Loading } from 'element-ui';
import addImage from "./addImage"
import groupInfo from "@/views/homepage/chat/module/groupInfo"
import userRadiobox from "@/views/homepage/chat/module/userRadiobox"
export default {
  mixins: [userInfo],
  components: {
    addImage,
    groupInfo,
    userRadiobox
  },
  data () {
    return {
      that: this,
      loading: false,
      textarea: "",
      friend_id: '',
      start_time: '',
      sendingList: [],  // 发送中的
      friendInfo: {
        nickname: "1112",
        avatar: 'https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg'
      },
      messageList: [
        {
          user_id: 111,
          content: "22233"
        }
      ]
    };
  },
  mounted () {
  },
  computed: {
    ...mapState({
      // messageList: (state) => state.jimSdk.chatDetail,
      // friendInfo: (state) => state.jimSdk.chatDetailFriendInfo
    }),
    imageSize () {
      return function (path) {
        const zoom = 1.5   // 图片缩放比   自己设定
        const list = path.split("_")
        const imagewidth = list[list.findIndex(item => item == "width") + 1]
        const imageheight = list[list.findIndex(item => item == "height") + 1]
        // const imagewidth = path.split("_")[2]
        // const imageheight = path.split("_")[4]
        const ratio = imagewidth / imageheight
        let width = imagewidth
        let height = imageheight
        if (ratio < 0.4) {
          width = 204; //这是从微信截图的长度最后需要同一除以 3
          height = 510;
        } else if (ratio >= 0.4 && ratio <= 0.5) {
          width = 204;
          height = 204 / ratio;
        } else if (ratio > 0.5 && ratio < 1) {
          width = 405 * ratio;
          height = 405;
        } else if (ratio >= 1 && ratio < 1 / 0.5) { //和前面的宽高转置
          height = 405 * (1 / ratio);
          width = 405;
        } else if (ratio >= 1 / 0.5 && ratio < 1 / 0.4) {
          height = 204;
          width = 204 / (1 / ratio);
        } else if (ratio >= 1 / 0.4) {
          height = 204; //这是从微信截图的长度最后需要同一除以 3
          width = 510;
        }
        return `width:${width / zoom}px;height:${height / zoom}px`
        // height /= 3;
        // width /= 3;
      }
    }
  },
  methods: {
    keyDown (e) {
      if (e.ctrlKey && e.keyCode == 13) {   //用户点击了ctrl+enter触发
        this.textarea += '\n';
      } else { //用户点击了enter触发
        e.preventDefault();
        this.sendFn();
      }
    },
    // 添加图片成功回调
    addImageSuccess (content) {
      this.sendMessage({
        content,
        id: this.$guid(),
        user_id: this.userInfo.uuid,
        message_type: "image",
        created_at_time_stamp: Date.parse(new Date()) / 1000,
        created_at: this.$timeFormat(null, "yyyy-mm-dd hh:MM:ss")
      })
    },
    // 发送
    sendFn () {
      const content = this.textarea
      this.textarea = ""
      this.sendMessage({
        content,
        id: this.$guid(),
        user_id: this.userInfo.uuid,
        message_type: "text",
        created_at_time_stamp: Date.parse(new Date()) / 1000,
        created_at: this.$timeFormat(null, "yyyy-mm-dd hh:MM:ss")
      })
    },
    // 请求发送
    async sendMessage (message) {
      const { content, message_type } = message
      console.log(content)
      if (this.$trim(content)) {
        this.sendingList.push(message)
        this.scrollEnd()
        await this.$store.dispatch("jimSdk/sendMessage", {
          that: this,
          content,
          target_username: this.friendInfo.username,
          friend_id: this.friendInfo.friend_id,
          extras: {
            msg: content,
            type: message_type
          }
        })
        this.$nextTick(() => {
          this.clear_sendingList(content)
        })
        this.$store.commit("jimSdk/pushChatDetailList", message)
        this.$nextTick(() => {
          this.scrollEnd()
        })
      } else {
        this.$message('请输入内容');
      }
    },
    // 清除发送中数据
    clear_sendingList (message) {
      this.sendingList = this.sendingList.filter((item) => {
        return !message.includes(item.content)
      })
    },
    //滚动到底部
    scrollEnd () {
      this.$nextTick(() => {
        let chatContent_box = document.getElementById('chatContent-box');
        chatContent_box.scrollTop = chatContent_box.scrollHeight
        // setTimeout(() =>
        //   chatContent_box.scrollTop = chatContent_box.scrollHeight
        //   , 1000)
      })
    },
    //重置会话未读数
    resetUnreadCount () {
      const { id: username } = this.$route.params
      this.$JIM.resetUnreadCount({ username });
    },
    // 聊天页初始化
    async chatDetail_init () {
      this.$store.commit("jimSdk/clearChatDetail")
      await this.$store.dispatch("jimSdk/_getChatDetail", { params: { friend_id: this.friend_id, start_time: this.start_time }, that: this })
      this.loading = false
      this.scrollEnd()
    },
    //  下拉加载更多
    async paperScroll (res) {
      if (res.target.scrollTop == 0) {
        let loadingInstance = Loading.service({ fullscreen: true });
        let chatContent_box = document.getElementById('chatContent-box');
        let oldHeight = chatContent_box.scrollHeight
        let newHeight = 0
        await this.$store.dispatch("jimSdk/_req_chatDetail", { params: { friend_id: this.friend_id, start_time: this.start_time }, that: this })
        await this.$nextTick(() => {
          newHeight = chatContent_box.scrollHeight;
        })
        chatContent_box.scrollTop = newHeight - oldHeight
        loadingInstance.close();
      }
    },
    groupInfoClick () {
      this.$refs.groupInfo.setShow()
    },
    //设置
    setting (command) {
      if (command === "zhuanrang") {
        this.$refs.userRadiobox.setDialogVisible(true)
      }

    }
  },
  filters: {
    time (index, that) {
      const time = that.$timeFormat(that.messageList[index].created_at_time_stamp, "yyyy-mm-dd hh:MM:ss")
      if (index == 0) {    //  如果是第一条，直接显示时间
        return time
      } else {
        if (that.messageList[index].created_at_time_stamp - that.messageList[index - 1].created_at_time_stamp < 180) {   // 大于多少秒不显示时间
          return ""
        } else {
          if (that.messageList[index].created_at_time_stamp > (new Date(new Date().toLocaleDateString()).getTime() / 1000)) {   //如果是今天  不显示年月日
            return that.$timeFormat(that.messageList[index].created_at_time_stamp, "hh:MM:ss")
          } else {
            return time
          }
        }
      }
    }
  },
  watch: {
    $route: {
      handler: function ({ params: { id } }) {
        this.friend_id = id
        this.start_time = this.$timeFormat(null, "yyyy-mm-dd hh:MM:ss")
        // this.$nextTick(() => {
        //   this.chatDetail_init()
        //   this.loading = true
        // })
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
.chatContent {
  width: 920px;
  height: 780px;
  background: #fff;
  &-time {
    line-height: 17px;
    color: #999999;
    font-size: 12px;
    text-align: center;
    margin-top: 16px;
  }
  &-t {
    line-height: 52px;
    height: 52px;
    text-align: center;
    border-bottom: #eee solid 1px;
    margin: 0 20px;
    font-size: 16px;
    color: #333;
    position: relative;
    &-btns {
      position: absolute;
      right: 0px;
      top: 0px;
      span {
        margin-left: 10px;
      }
    }
  }
  &-c {
    height: 475px;
    overflow: auto;
    &-friend,
    &-mine {
      padding: 0 20px;
      width: 390px;
      display: flex;
      margin: 20px 0;
      width: 100%;
      font-size: 14px;
      color: #333;
      &-message {
        border-radius: 6px;
        padding: 11px 9px;
        line-height: 20px;
        font-size: 14px;
        position: relative;
        max-width: 390px;
        word-wrap: break-word;
        word-break: normal;
        white-space: pre-wrap;
      }
    }
    &-friend {
      &-message {
        background: #f7f7f7;
        margin-left: 16px;
      }
      &-triangle {
        display: block;
        margin: 5px auto;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-right-color: #f7f7f7;
        position: absolute;
        left: -10px;
      }
    }
    &-mine {
      justify-content: flex-end;
      &-message {
        margin-right: 16px;
        background: #f4faff;
      }
      &-triangle {
        display: block;
        margin: 5px auto;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-left-color: #f4faff;
        position: absolute;
        right: -10px;
      }
    }
  }
  &-b {
    margin: 0 20px;
    height: 254px;
    border-top: 1px solid #eee;
    &-t {
      height: 44px;
      font-size: 20px;
      line-height: 44px;
      color: #ccc;
    }
    &-c {
      height: 146px;
      & /deep/ .el-textarea__inner {
        padding: 0px;
        border: none;
      }
    }
    &-b {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &-help {
        color: #999;
        font-size: 14px;
        margin-right: 10px;
      }
      &-btn {
        width: 72px;
        line-height: 32px;
        background: #2990f9;
        border-radius: 4px;
        text-align: center;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}
.sending {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
</style>