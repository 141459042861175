/* 
 * @Author: 曹俊杰 
 * @Date: 2021-03-04 16:19:29
 * @Module: 群资料
 */
 <template>
  <div class="groupInfo "
       :class="{hidden:!show}">
    <div class="groupInfo-name">
      <div class="title">群聊名称</div>
      <div class="content">郑州腾讯ISUX用户体验中心</div>
    </div>
    <div class="groupInfo-postscript">
      <div class="title">备注</div>
      <div class="content">
        <span>群聊的备注仅自己可见</span>
        &nbsp;
        <el-popover placement="bottom"
                    width="400"
                    trigger="click">
          <el-input v-model="postscript"
                    size="mini"
                    placeholder="请输入备注">
            <el-button slot="append"
                       icon="el-icon-check"></el-button>
          </el-input>
          <span slot="reference"
                class="el-icon-edit hover" />
        </el-popover>
      </div>
    </div>
    <div class="groupInfo-notice">
      <div class="title">群聊公告</div>
      <div class="content">暂无公告</div>
    </div>
    <div class="solid"></div>
    <div style="padding:0 20px">
      <el-input placeholder="搜索"
                size="small "
                prefix-icon="el-icon-search"
                v-model="search">

      </el-input>
    </div>
    <div class="btns">
      <div>
        <el-button icon="el-icon-plus"
                   size="small "
                   circle></el-button>
        <span>邀请成员</span>
      </div>
      <div>
        <el-button icon="el-icon-minus"
                   size="small "
                   circle></el-button>
        <span>移除成员</span>
      </div>
    </div>
    <div class="userList">
      <div class="item"
           v-for="index in 10"
           :key="index">
        <el-avatar :size="32"
                   src="https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg"></el-avatar>
        <span class="name">李老师</span>
      </div>
    </div>
    <div class="out">退出群聊</div>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      show: true,
      postscript: "",
      search: ""
    };
  },
  mounted () { },
  methods: {
    okClick () {
      this.setShow()
    },
    setShow () {
      this.show = !this.show
    }
  },
};
 </script>
 <style lang='scss' scoped>
.groupInfo {
  height: 727px;
  width: 260px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  // animation-duration: 0.3s;
  // animation-timing-function: ease;
  // animation-delay: 1ms;
  // animation-iteration-count: 1;
  // animation-direction: normal;
  // animation-fill-mode: none;
  // animation-play-state: running;
  // animation-name: rtl-drawer-in;
  transition: right 0.5s;
  &-name,
  &-postscript,
  &-notice {
    margin: 0px 20px;
    .content {
      padding-bottom: 7px;
    }
  }
  &-name {
    margin-top: 10px;
    .content {
      font-size: 12px;
      color: #262626;
      line-height: 17px;
      // font-weight: 600;
    }
  }
  &-postscript {
    margin-top: 2px;
    .content {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
    }
  }
  &-notice {
    margin-top: 2px;
    .content {
      font-size: 12px;
      color: #262626;
      line-height: 17px;
      // font-weight: 600;
    }
  }
  .btns {
    margin-top: 10px;
    div {
      height: 46px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
    }
  }
  .userList {
    // padding-bottom: 45px;
    // background: #f66;
    height: 366px;
    overflow: auto;
    .item {
      height: 46px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      .name {
        margin-left: 10px;
      }
    }
  }
  .out {
    line-height: 45px;
    text-align: center;
    color: #666666;
    font-size: 14px;
    // position: absolute;
    // bottom: 0px;
    width: 100%;
    border-top: solid 1px #eee;
  }
}
.hidden {
  right: -260px;
  box-shadow: none;
}
.title {
  font-size: 12px;
  color: #999999;
  line-height: 17px;
  margin: 5px 0px;
}
.solid {
  width: 220px;
  height: 1px;
  background: #eeeeee;
  margin: 11px auto;
}
</style>